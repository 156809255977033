import { onValue, ref, update } from 'firebase/database'
import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { Loader } from '../components/Loader'
import { database } from '../data/firebase'

import { SettingsPage } from '../pages/SettingsPage'
import { useAuthContext } from './auth-context'

const isSettings = (object: unknown): object is Settings =>
    object !== null &&
    typeof object === 'object' &&
    'listLayout' in object &&
    'hideStatistics' in object

export type Settings = {
    listLayout: boolean
    hideStatistics: boolean
}

type SettingsBody = Settings & {
    showSettings: () => void
}

const SettingsContext = createContext<SettingsBody | null>(null)

function SettingsProvider({ children }: { children: React.ReactNode }) {
    const { user } = useAuthContext()
    const settingsRef = useMemo(
        () => ref(database, user.uid + '/settings'),
        [user.uid]
    )
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)

    const [settings, setSettings] = useState<Settings | null>(null)

    const updateSettings = useCallback(
        (values: Partial<Settings>) => {
            update(settingsRef, values).catch(console.error)
        },
        [settingsRef]
    )

    useEffect(() => {
        const unsubscribe = onValue(settingsRef, (snapshot) => {
            const value: unknown = snapshot.val()
            if (!isSettings(value)) {
                updateSettings({ listLayout: false, hideStatistics: false })
                return
            }
            setSettings(value)
        })
        return unsubscribe
    }, [settingsRef, updateSettings])

    if (settings === null) {
        return <Loader />
    }

    if (isSettingsOpen) {
        return (
            <SettingsPage
                settings={settings}
                updateSettings={updateSettings}
                hideSettings={() => setIsSettingsOpen(false)}
            />
        )
    }

    return (
        <SettingsContext.Provider
            value={{
                ...settings,
                showSettings: () => setIsSettingsOpen(true),
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

const useSettingsContext = () => {
    const context = useContext(SettingsContext)
    if (!context) {
        throw new Error(
            'useSettingsContext must be used within a SettingsProvider'
        )
    }
    return context
}

export { SettingsProvider, useSettingsContext }
