import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { theme } from '../../theme'

export function Loader() {
    return (
        <View>
            <ActivityIndicator size={'large'} color={theme.colors.warning} />
        </View>
    )
}
