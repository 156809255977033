import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { theme } from '../../theme'
import { useSettingsContext } from '../contexts/settings-context'
import { TodoData } from './TodoList'

export function LastTodo({ text, isCompleted }: Omit<TodoData, 'id'>) {
    const { listLayout } = useSettingsContext()
    return (
        <View
            style={isCompleted ? styles.completedContainer : styles.container}
        >
            <Text
                style={[
                    styles.text,
                    isCompleted && {
                        textDecorationLine: 'line-through',
                        textDecorationStyle: 'dashed',
                    },
                    listLayout && {
                        textAlign: 'left',
                    },
                ]}
            >
                {text}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderRadius: 4,
        padding: 16,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.colors.muted,
    },
    completedContainer: {
        flex: 1,
        borderRadius: 4,
        padding: 16,
        borderWidth: 1,
        borderColor: 'transparent',
    },

    text: {
        flex: 1,
        fontSize: 14,
        textAlign: 'center',
        color: theme.colors.muted,
    },
})
