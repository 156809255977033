// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDFfqvtuuhW-TxSmb9z199XKvjxxvSA690',
    authDomain: 'three-things-9ac05.firebaseapp.com',
    projectId: 'three-things-9ac05',
    storageBucket: 'three-things-9ac05.appspot.com',
    messagingSenderId: '542765586253',
    appId: '1:542765586253:web:36ceac00a9f0a09b758904',
    measurementId: 'G-YPB8PGYRBZ',
    databaseURL:
        'https://three-things-9ac05-default-rtdb.europe-west1.firebasedatabase.app',
}

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig)
export const analytics = getAnalytics(firebase)
export const database = getDatabase(firebase)
export const auth = getAuth(firebase)

export default firebase
