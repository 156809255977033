import React, { ReactNode } from 'react'
import { View, StyleSheet } from 'react-native'

export function Header({ action }: { action: ReactNode }) {
    return <View style={styles.container}>{action}</View>
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        padding: 16,
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
})
