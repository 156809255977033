import React from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import { DayHandler } from './src/components/DayHandler'
import { AuthProvider } from './src/contexts/auth-context'
import { SettingsProvider } from './src/contexts/settings-context'
import TodoPage from './src/pages/TodoPage'
import { theme } from './theme'

export default function App() {
    return (
        <SafeAreaView style={styles.container}>
            <AuthProvider>
                <DayHandler>
                    <SettingsProvider>
                        <TodoPage />
                    </SettingsProvider>
                </DayHandler>
            </AuthProvider>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.colors.background,
        alignItems: 'center',
        justifyContent: 'center',
    },
})
