import { Ionicons } from '@expo/vector-icons'
import React from 'react'
import { Pressable, Text, View, StyleSheet } from 'react-native'
import { theme } from '../../theme'
import { Spacer } from '../components/Spacer'

export function LoginPage({
    loginAnonymously,
    loginWithGoogle,
}: {
    loginAnonymously: () => void
    loginWithGoogle: () => void
}) {
    return (
        <View style={styles.container}>
            <Pressable
                onPress={() => loginWithGoogle()}
                style={styles.googleButton}
            >
                <Ionicons size={24} name="logo-google" />
                <Spacer horizontal={8} />
                <Text>Continue with Google</Text>
            </Pressable>
            <Spacer vertical={16} />
            <Pressable
                onPress={() => loginAnonymously()}
                style={styles.anonymousButton}
            >
                <Text>Continue as Guest</Text>
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {},
    googleButton: {
        backgroundColor: theme.colors.elementBackground,
        padding: 16,
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    anonymousButton: {
        alignSelf: 'center',
        textDecorationLine: 'underline',
    },
})
