import { FirebaseError } from 'firebase/app'
import {
    getRedirectResult,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInAnonymously,
    signInWithRedirect,
    signOut,
} from 'firebase/auth'
import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react'
import { Error as ErrorPage, ErrorType } from '../components/Error'
import { Loader } from '../components/Loader'
import { auth } from '../data/firebase'
import { LoginPage } from '../pages/LoginPage'

type User = {
    uid: string
    displayName: string | null
    email: string | null
    isAnonymous: boolean
}

type AuthBody = {
    user: User
    logout: () => void
    showLogin: () => void
}

const googleProvider = new GoogleAuthProvider()
googleProvider.setCustomParameters({ prompt: 'select_account' })

const AuthContext = createContext<AuthBody | null>(null)

function AuthProvider({ children }: { children: React.ReactNode }) {
    const [isAuthLoading, setIsAuthLoading] = useState(true)
    const [showLoginPage, setShowLoginPage] = useState(false)
    const [error, setError] = useState<ErrorType | null>(null)

    const [user, setUser] = useState<User | null>(auth.currentUser)

    const logout = () => {
        signOut(auth)
            .then(() => setUser(null))
            .catch((error) => console.error(error))
    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthLoading(false)
            setUser(user)
        })
        return unsubscribe
    }, [])

    const loginAnonymously = useCallback(() => {
        if (user && showLoginPage) {
            setShowLoginPage(false)
            return
        }
        setIsAuthLoading(true)
        signInAnonymously(auth)
            .then(() => console.log('User signed in anonymously'))
            .catch((error) => console.error('Authentication failed', error))
    }, [showLoginPage, user])

    const loginWithGoogle = useCallback(() => {
        setIsAuthLoading(true)
        signInWithRedirect(auth, googleProvider)
            .then(() => console.log('User signed in anonymously'))
            .catch((error) => console.error('Authentication failed', error))
    }, [])

    useEffect(() => {
        getRedirectResult(auth)
            .then((result) => {
                if (result) {
                    const user = result.user
                    setUser(user)
                }
            })
            .catch((error) => {
                if (error instanceof FirebaseError) {
                    setError({
                        message:
                            'Authentication failed with message: ' +
                            error.message,
                        action: () => loginWithGoogle(),
                    })
                }
            })
    }, [loginWithGoogle])

    if (error) {
        return <ErrorPage {...error} />
    }

    if (isAuthLoading) {
        return <Loader />
    }

    if (!user || showLoginPage) {
        return (
            <LoginPage
                loginAnonymously={loginAnonymously}
                loginWithGoogle={loginWithGoogle}
            />
        )
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                logout,
                showLogin: () => setShowLoginPage(true),
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

const useAuthContext = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuthContext must be used within a AuthProvider')
    }
    return context
}

export { AuthProvider, useAuthContext }
