import { push, ref, set } from 'firebase/database'
import React, { useEffect, useMemo, useState } from 'react'
import { Pressable, StyleSheet, TextInput, Text } from 'react-native'
import { theme } from '../../theme'
import { useAuthContext } from '../contexts/auth-context'
import { database } from '../data/firebase'

const randomPlaceholders = [
    'Hope this is the last one',
    'There is a day tomorrow also',
    'Save something for the rest of us',
]

export function NewTodo({ isOverLimit }: { isOverLimit: boolean }) {
    const [hideInput, setHideInput] = useState(false)
    const [text, setText] = useState('')

    const { user } = useAuthContext()
    const todosRef = useMemo(
        () => ref(database, user.uid + '/todos'),
        [user.uid]
    )

    const addTodo = ({ text }: { text: string }) => {
        set(push(todosRef), { text, isCompleted: false }).catch((error) =>
            console.warn(error)
        )
    }

    useEffect(() => {
        if (isOverLimit) {
            setHideInput(true)
        }
    }, [isOverLimit])

    const handleSubmit = () => {
        setHideInput(true)
        Boolean(text) && addTodo({ text })
        setText('')
    }

    if (isOverLimit && hideInput) {
        return (
            <Pressable
                style={styles.oneMoreButton}
                onPress={() => setHideInput(false)}
            >
                <Text style={styles.oneMoreText}>One More Thing</Text>
            </Pressable>
        )
    }

    return (
        <TextInput
            style={[styles.textInput, isOverLimit && styles.overLimit]}
            textAlignVertical="top"
            autoFocus
            placeholder={
                isOverLimit
                    ? randomPlaceholders[
                          Math.floor(Math.random() * randomPlaceholders.length)
                      ]
                    : 'Add new todo'
            }
            value={text}
            onChangeText={setText}
            onSubmitEditing={handleSubmit}
            blurOnSubmit={false}
        />
    )
}

const styles = StyleSheet.create({
    textInput: {
        borderBottomWidth: 1,
        fontSize: 14,
        padding: 8,
        width: '98%',
    },
    overLimit: {
        borderBottomColor: theme.colors.warning,
    },
    oneMoreButton: {
        borderWidth: 1,
        borderColor: theme.colors.warning,
        borderRadius: 16,
        alignSelf: 'center',
        padding: 8,
    },
    oneMoreText: {
        fontSize: 10,
        color: theme.colors.warning,
    },
})
