import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import { Statistics } from '../components/Statistics'
import { TodoData, TodoList } from '../components/TodoList'
import { theme } from '../../theme'

import { Header } from '../components/Header'
import { ref, onValue } from 'firebase/database'
import { useAuthContext } from '../contexts/auth-context'
import { database } from '../data/firebase'
import { Loader } from '../components/Loader'
import { NewTodo } from '../components/NewTodo'
import { Ionicons } from '@expo/vector-icons'
import { useSettingsContext } from '../contexts/settings-context'
import { LastTodoList } from '../components/LastTodoList'
import { Spacer } from '../components/Spacer'

export const isTodoData = (value: unknown): value is Omit<TodoData, 'id'> => {
    return (
        value !== null &&
        typeof value === 'object' &&
        'text' in value &&
        'isCompleted' in value
    )
}

export default function TodoPage() {
    const { showSettings, hideStatistics, listLayout } = useSettingsContext()
    const [todos, setTodos] = useState<TodoData[] | null>(null)
    const has3Todos = todos && todos.length >= 3
    const has10Todos = todos && todos.length >= 10
    const [lastTodos, setLastTodos] = useState<TodoData[] | null>(null)

    const { user } = useAuthContext()
    const todosRef = useMemo(
        () => ref(database, user.uid + '/todos'),
        [user.uid]
    )

    const lastTodosRef = useMemo(
        () => ref(database, user.uid + '/lastTodos'),
        [user.uid]
    )
    useEffect(() => {
        const unsubscribe = onValue(lastTodosRef, (snapshot) => {
            const newTodos: TodoData[] = []
            snapshot.forEach((childSnapshot) => {
                const value: unknown = childSnapshot.val()
                const key = childSnapshot.key
                if (!isTodoData(value) || !key) {
                    return
                }

                newTodos.push({ id: key, ...value })
            })
            setLastTodos(newTodos)
        })
        return unsubscribe
    }, [lastTodosRef])

    useEffect(() => {
        const unsubscribe = onValue(todosRef, (snapshot) => {
            const newTodos: TodoData[] = []
            snapshot.forEach((childSnapshot) => {
                const value: unknown = childSnapshot.val()
                const key = childSnapshot.key
                if (!isTodoData(value) || !key) {
                    return
                }

                newTodos.push({ id: key, ...value })
            })
            setTodos(newTodos)
        })
        return unsubscribe
    }, [todosRef])

    if (todos === null || lastTodos === null) {
        return <Loader />
    }

    const todaysCompletedTodos = todos.filter(
        ({ isCompleted }) => isCompleted
    ).length
    const lastCompletedTodos = lastTodos.filter(
        ({ isCompleted }) => isCompleted
    ).length
    return (
        <>
            <Header
                action={
                    <Pressable onPress={() => showSettings()}>
                        <Ionicons name="cog-outline" size={24} />
                    </Pressable>
                }
            />
            <View style={styles.container}>
                {!hideStatistics && (
                    <Statistics
                        todaysCompletedTodos={todaysCompletedTodos}
                        lastCompletedTodos={lastCompletedTodos}
                    />
                )}
                <Text style={styles.currentDate}>
                    {format(new Date(), 'MMMM do')}
                </Text>
                <Spacer top={32} />
                <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={
                        listLayout
                            ? styles.todoContainerList
                            : styles.todoContainer
                    }
                >
                    {lastTodos.length > 0 && (
                        <LastTodoList lastTodos={lastTodos} />
                    )}
                    <TodoList todos={todos} />
                </ScrollView>
                <Spacer top={32} />
                {!has10Todos && <NewTodo isOverLimit={Boolean(has3Todos)} />}
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 64,
        paddingHorizontal: 16,
        maxWidth: 440,
        width: '100%',
        backgroundColor: theme.colors.background,
        alignItems: 'center',
        justifyContent: 'center',
    },
    currentDate: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    scrollView: {
        padding: 8,
        width: '100%',
        overflow: 'scroll',
    },
    todoContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    todoContainerList: {
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
    },
})
