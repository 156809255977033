import { Ionicons } from '@expo/vector-icons'
import { child, get, ref } from 'firebase/database'
import React, { useCallback, useEffect, useState } from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { useAuthContext } from '../contexts/auth-context'
import { database } from '../data/firebase'

export function Statistics({
    todaysCompletedTodos,
    lastCompletedTodos,
}: {
    todaysCompletedTodos: number
    lastCompletedTodos: number
}) {
    const { user } = useAuthContext()
    const userRef = ref(database, user.uid)
    const completedTodosRef = child(userRef, 'completedTodos')
    const daysWithCompletedTodosRef = child(userRef, 'daysWithCompletedTodos')

    const [previouslyCompletedTodos, setPreviouslyCompeletedTodos] = useState(0)
    const [daysWithCompletedTodos, setDaysWithCompletedTodos] = useState(0)

    const getCompletedTodos = useCallback(async () => {
        const snapshot = await get(completedTodosRef)
        const value: unknown = snapshot.val()
        if (typeof value === 'number') {
            setPreviouslyCompeletedTodos(value)
        }
    }, [completedTodosRef])

    const getDaysWithCompletedTodos = useCallback(async () => {
        const snapshot = await get(daysWithCompletedTodosRef)
        const value: unknown = snapshot.val()
        if (typeof value === 'number') {
            setDaysWithCompletedTodos(value)
        }
    }, [daysWithCompletedTodosRef])

    useEffect(() => {
        getCompletedTodos().catch(console.error)
        getDaysWithCompletedTodos().catch(console.error)
    }, [getCompletedTodos, getDaysWithCompletedTodos])

    const totalDaysWithCompletedTodos =
        daysWithCompletedTodos +
        (lastCompletedTodos >= 1 ? 1 : 0) +
        (todaysCompletedTodos >= 1 ? 1 : 0)

    const totalCompletedTodos =
        previouslyCompletedTodos + lastCompletedTodos + todaysCompletedTodos

    return (
        <View style={styles.container}>
            <View style={styles.statContainer}>
                <Ionicons name="calendar-outline" size={22} />
                <Text>{totalDaysWithCompletedTodos}</Text>
            </View>
            <View style={styles.statContainer}>
                <Ionicons name="rocket-outline" size={22} />
                <Text>{totalCompletedTodos}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    statContainer: {
        flex: 1,
        margin: 16,
        alignItems: 'center',
        justifyContent: 'center',
    },
})
