import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Spacer } from './Spacer'

export type ErrorType = {
    message: string
    action: () => void
}
export function Error({ message, action }: ErrorType) {
    return (
        <View style={styles.container}>
            <Text style={styles.message}>{message}</Text>
            <Spacer top={16} />
            <Pressable onPress={action}>
                <Text>{'Try again'}</Text>
            </Pressable>
            <Spacer top={16} />
            <Text>{'Please contact me if the issue persists'}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {},
    message: {
        width: '100%',
        maxWidth: 400,
    },
})
