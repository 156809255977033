import { isSameDay } from 'date-fns'
import { get, update, ref } from 'firebase/database'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Settings } from 'react-native'
import { useAuthContext } from '../contexts/auth-context'
import { database } from '../data/firebase'
import { Error as ErrorPage } from './Error'
import { Loader } from './Loader'
import { TodoData } from './TodoList'

type UserData = {
    date: string
    todos: TodoData[]
    lastDate: string
    lastTodos: TodoData[]
    completedTodos: number
    daysWithCompletedTodos: number
    settings: Settings
}

const isUserData = (data: unknown): data is Partial<UserData> =>
    data !== null && typeof data === 'object' && 'date' in data

export function DayHandler({ children }: { children: ReactNode }) {
    const [hasUpdated, setHasUpdated] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { user } = useAuthContext()
    const userDataRef = ref(database, user.uid)

    const updateUserData = useCallback(async () => {
        try {
            const snapshot = await get(userDataRef)
            const value: unknown = snapshot.val()

            if (!isUserData(value)) {
                await update(userDataRef, { date: new Date().toISOString() })
                return
            }
            if (value.date && isSameDay(new Date(), new Date(value.date))) {
                return
            }

            const completedLastTodos =
                (value.lastTodos &&
                    Object.values(value.lastTodos).filter(
                        ({ isCompleted }) => isCompleted
                    ).length) ||
                0
            const completedTodos =
                (value.completedTodos || 0) + completedLastTodos

            const daysWithCompletedTodos = completedLastTodos
                ? (value.daysWithCompletedTodos || 0) + 1
                : value.daysWithCompletedTodos

            const settings = value.settings || {
                listLayout: false,
                hideStatistics: false,
            }

            await update(userDataRef, {
                lastTodos: value.todos || null,
                lastDate: value.date || null,
                todos: {},
                date: new Date().toISOString(),
                completedTodos: completedTodos || 0,
                daysWithCompletedTodos: daysWithCompletedTodos || 0,
                settings: settings,
            })
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message)
            } else {
                setErrorMessage('Something went wrong')
            }
        }
    }, [userDataRef])

    useEffect(() => {
        if (!hasUpdated) {
            updateUserData()
                .then(() => setHasUpdated(true))
                .catch(console.error)
        }
    }, [hasUpdated, updateUserData])

    if (errorMessage) {
        return (
            <ErrorPage
                message={
                    'Failed to update user data with message: ' + errorMessage
                }
                action={() => {
                    updateUserData().catch(console.error)
                }}
            />
        )
    }

    if (!hasUpdated) {
        return <Loader />
    }

    return <>{children}</>
}
