import { Ionicons } from '@expo/vector-icons'
import React from 'react'
import { Pressable, StyleSheet, Switch, Text, View } from 'react-native'
import { theme } from '../../theme'
import { Header } from '../components/Header'
import { Spacer } from '../components/Spacer'
import { useAuthContext } from '../contexts/auth-context'
import { Settings } from '../contexts/settings-context'

const settingLabels: Record<keyof Settings, string> = {
    listLayout: 'Use List Layout',
    hideStatistics: "Don't Show Statistics",
}

export function SettingsPage({
    settings,
    updateSettings,
    hideSettings,
}: {
    settings: Settings
    updateSettings: (settings: Partial<Settings>) => void
    hideSettings: () => void
}) {
    const { logout, user, showLogin } = useAuthContext()

    return (
        <>
            <Header
                action={
                    <Pressable onPress={hideSettings}>
                        <Ionicons name="close" size={24} />
                    </Pressable>
                }
            />

            <View style={styles.container}>
                {Object.entries(settings).map(([key, value]) => {
                    const onChange = (value: boolean) => {
                        updateSettings({ [key]: value })
                    }
                    const typedKey = key as keyof Settings

                    return (
                        <View key={key} style={styles.setting}>
                            <Text style={styles.settingLabel}>
                                {settingLabels[typedKey]}
                            </Text>
                            <Switch
                                value={value}
                                onValueChange={onChange}
                                trackColor={{
                                    false: theme.colors.text,
                                    true: theme.colors.elementBackground,
                                }}
                                thumbColor={theme.colors.elementBackground}
                            />
                        </View>
                    )
                })}
                <Spacer top={16} />

                <Text>
                    Signed in as{' '}
                    {user.isAnonymous
                        ? 'Guest'
                        : user.displayName || user.email}
                </Text>
                <Pressable style={styles.logoutButton} onPress={() => logout()}>
                    <Text>Sign out</Text>
                    <Spacer left={8} />
                    <Ionicons name="log-out-outline" size={24} />
                </Pressable>
                {user.isAnonymous && (
                    <>
                        <Text style={styles.warningText}>
                            Warning: All data will be lost on logout, as this is
                            a guest account
                        </Text>
                        <Pressable onPress={() => showLogin()}>
                            <Text>Link account</Text>
                        </Pressable>
                    </>
                )}
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        maxWidth: 400,
    },

    setting: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 16,
    },
    settingLabel: {
        fontSize: 16,
    },

    warningText: {
        color: theme.colors.warning,
    },
    logoutButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
})
