import React from 'react'
import { Pressable } from 'react-native'
import { Todo } from './Todo'
import { Spacer } from './Spacer'
import { useAuthContext } from '../contexts/auth-context'
import { database } from '../data/firebase'
import { ref, remove, child, update } from 'firebase/database'

export interface TodoData {
    id: string
    text: string
    isCompleted: boolean
}

export function TodoList({ todos }: { todos: TodoData[] }) {
    const { user } = useAuthContext()
    const todosRef = ref(database, user.uid + '/todos')

    const deleteTodo = ({ id }: { id: string }) => {
        const reference = child(todosRef, id)
        remove(reference).catch((error) => console.warn(error))
    }
    const toggleTodoCompleted = ({
        id,
        isCompleted,
    }: {
        id: string
        isCompleted: boolean
    }) => {
        const reference = child(todosRef, id)
        update(reference, { isCompleted }).catch((error) => console.warn(error))
    }

    return (
        <>
            {todos.map(({ id, text, isCompleted }, index) => (
                <Spacer key={index} margin={8}>
                    <Pressable
                        onPress={() =>
                            toggleTodoCompleted({
                                id,
                                isCompleted: !isCompleted,
                            })
                        }
                        delayLongPress={500}
                        onLongPress={() => deleteTodo({ id })}
                    >
                        <Todo text={text} isCompleted={isCompleted} />
                    </Pressable>
                </Spacer>
            ))}
        </>
    )
}
