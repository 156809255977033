import React, { useEffect, useMemo, useState } from 'react'
import { Pressable } from 'react-native'
import { Spacer } from './Spacer'
import { useAuthContext } from '../contexts/auth-context'
import { database } from '../data/firebase'
import { child, ref, remove, update } from 'firebase/database'
import { LastTodo } from './LastTodo'
import { TodoData } from './TodoList'

export function LastTodoList({ lastTodos }: { lastTodos: TodoData[] }) {
    const { user } = useAuthContext()

    const lastTodosRef = useMemo(
        () => ref(database, user.uid + '/lastTodos'),
        [user.uid]
    )
    const [hiddenTodos, setHiddenTodos] = useState<string[]>(
        lastTodos.filter(({ isCompleted }) => isCompleted).map(({ id }) => id)
    )

    useEffect(() => {
        const timers = lastTodos
            .filter(({ isCompleted }) => isCompleted)
            .map(({ id }) => {
                return setTimeout(() => {
                    setHiddenTodos((prev) => [...prev, id])
                }, 3000)
            })
        return () => timers.forEach((timer) => clearTimeout(timer))
    }, [lastTodos])

    const toggleTodoCompleted = ({
        id,
        isCompleted,
    }: {
        id: string
        isCompleted: boolean
    }) => {
        const reference = child(lastTodosRef, id)
        update(reference, { isCompleted }).catch((error) => console.warn(error))
    }

    const deleteTodo = ({ id }: { id: string }) => {
        const reference = child(lastTodosRef, id)
        remove(reference).catch((error) => console.warn(error))
    }

    return (
        <>
            {lastTodos
                .filter(({ id }) => !hiddenTodos.includes(id))
                .map(({ id, text, isCompleted }, index) => (
                    <Spacer key={index} margin={8}>
                        <Pressable
                            onPress={() =>
                                toggleTodoCompleted({
                                    id,
                                    isCompleted: !isCompleted,
                                })
                            }
                            delayLongPress={500}
                            onLongPress={() => deleteTodo({ id })}
                        >
                            <LastTodo text={text} isCompleted={isCompleted} />
                        </Pressable>
                    </Spacer>
                ))}
        </>
    )
}
