import React, { useCallback, useEffect, useRef } from 'react'
import { StyleSheet, Text, Animated } from 'react-native'
import { theme } from '../../theme'
import { useSettingsContext } from '../contexts/settings-context'
import { TodoData } from './TodoList'

export function Todo({
    text,
    isCompleted,
}: Omit<TodoData, 'id'> & { isOldTodo?: boolean }) {
    const { listLayout } = useSettingsContext()

    // fadeAnim will be used as the value for opacity. Initial Value: 0
    const fadeAnim = useRef(new Animated.Value(0)).current

    const fadeIn = useCallback(() => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start()
    }, [fadeAnim])

    const fadeOut = useCallback(() => {
        // Will change fadeAnim value to 0 in 3 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start()
    }, [fadeAnim])

    useEffect(() => {
        if (!isCompleted) {
            fadeIn()
        } else {
            fadeOut()
        }
    }, [fadeIn, fadeOut, isCompleted])

    return (
        <Animated.View
            style={[
                styles.container,
                {
                    backgroundColor: fadeAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [
                            'rgba(255,255,255,0)',
                            theme.colors.elementBackground,
                        ],
                    }),
                    borderColor: fadeAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [
                            'rgba(255,255,255,0)',
                            theme.colors.elementBackground,
                        ],
                    }),
                },
            ]}
        >
            <Text
                style={[
                    styles.text,
                    isCompleted && { textDecorationLine: 'line-through' },
                    listLayout && {
                        textAlign: 'left',
                    },
                ]}
            >
                {text}
            </Text>
        </Animated.View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderRadius: 4,
        padding: 16,
        borderWidth: 1,
        borderColor: theme.colors.elementBackground,
        maxWidth: 360,
    },
    text: {
        flex: 1,
        fontSize: 14,
        textAlign: 'center',
    },
})
